<template>
    <div class="duibidetails-main" ref="mainScroll" id="articleScroll">
        <div class="detail-head">
            <p class="title">{{detailData.peUserName}}{{$t('pe.medicalExaminationReportContrast')}}</p>
            <p class="text"> {{$t('pe.reportDesc',{name:detailData.peUserName,hospName:detailData.hospName})}}</p>
            <p class="text">{{$t('pe.tips')}}</p>
            <p class="text lastext">{{$t('pe.bless',{hospName:detailData.hospName})}}</p>
            <div class="vs-box" ref="vsRef">
                <div class="boxs" v-if="detailData&&detailData.titles">
                    <span class="timeText">{{detailData.titles[0]}}</span>
                    <span class="timeNull">&nbsp;</span>
                    <span class="timeText">{{detailData.titles[1]}}</span>
                </div>
            </div>
            <div class="vs-box VsFixed" v-show="DivShow">
                <div class="boxs" v-if="detailData&&detailData.titles">
                    <span class="timeText">{{detailData.titles[0]}}</span>
                    <span class="timeNull">&nbsp;</span>
                    <span class="timeText">{{detailData.titles[1]}}</span>
                </div>
            </div>
        </div>
        <div class="detail-list" v-if="detailData.values">
            <div class="Lsit-item" v-for="(item,index) in detailData.values" :key="index">
                <p class="tj-title">{{item.title}}</p>
                <div class="item-child" v-for="(citem,cindex) in item.values" :key="cindex">
                    <div class="child-title">
                        <span class="text">{{citem.title}}{{citem.unit}}【{{$t('pe.referenceRange')}}：{{citem.values[2]}}】</span>
                        <i class="icon" @click="citem.setdesc = !citem.setdesc" v-if="citem.desc.desc" :class="{'iconBottom':citem.setdesc}"></i>
                    </div>
                    <div class="child-box">
                        <div :class="{'text-red':citem.desc.isNormal==0||citem.desc.isPreNormal==0}">{{citem.values[0]}}</div>
                        <div :class="{'text-red':citem.desc.isNormal==0||citem.desc.isPreNormal==0}"><span>{{citem.values[1]}}</span><i :class="citem.desc.isDown==0&&citem.desc.isUp==0?'':citem.desc.isDown==1?'arrow-bottom':citem.desc.isUp==1?'arrow-top':''"></i></div>
                    </div>
                    <div class="desc-text" v-show="citem.setdesc" v-if="citem.desc.desc">
                        <p class="txt">{{citem.desc.desc}}12313</p>
                    </div>
                </div>
            </div>
        </div>
         <!-- 悬浮咨询 -->
        <div class="article-consult" ref="consultBox"  :class="{'Moving':transitionType}" @click="goPath">
            <img src="@/assets/img/consult-icon.png" >
            <p class="txt">{{$t("pe.consultService")}}</p>
        </div>
    </div>
</template>

<script>
import { compareDetails } from "@/api/medicalReport";
export default {
    data() {
        return {
            peCodes:[],
            detailData:{},
			// 悬浮 文字控制
			transitionType:false,
            DivShow:false,
        }
    },
    created(){
        document.title = this.$t("pe.medicalContrast");
        this.peCodes = this.$route.query.peCodes;
        this.getDetailsData();
        
    },
    mounted(){
        this.$refs.mainScroll.addEventListener("scroll", (e)=>{
            // console.log(e.target.scrollTop);
            // console.log(this.$refs.vsRef.offsetTop);
            if(e.target.scrollTop>=this.$refs.vsRef.offsetTop){
                this.DivShow = true;
            }else{
                this.DivShow = false;
            }
        });
    	this.handleScroll();
    },
	beforeDestroy() {
		articleScroll.removeEventListener('scroll'); //离开当前组件别忘记移除事件监听哦
	},
    methods:{
        handleScroll() {
			articleScroll.addEventListener('scroll', () => {
				clearTimeout(this.timer);
  				this.isScrollEnd();
				this.scrollTop = articleScroll.scrollTop;
				if(!this.transitionType){
					this.Startconsult();
					this.transitionType = true;
				}
			})
		},
		isScrollEnd(){
			this.timer = setTimeout(()=>{
				this.EndScrollTop = articleScroll.scrollTop;
				if(this.scrollTop == articleScroll.scrollTop){
					setTimeout(()=>{
						this.endconsult();
						this.transitionType = false;
					},500)
				}
			},50)
		},
		goPath(){
            this.$router.push({
                path:"/im/chat",
                query:{
                    fromPage:120018,
					objValue:this.peCodes[0],
                }
            })
		},
		Startconsult(){
			// console.log(this.$refs);
			this.$refs.consultBox.style.opacity = 1;
			let timed = setInterval(()=>{
				if(this.$refs.consultBox.style.opacity>=0.4){
					this.$refs.consultBox.style.opacity -= 0.1; 
				}else{
					this.$refs.consultBox.style.opacity = .2;
					clearInterval(timed);
				}
			},30)
			this.$refs.consultBox.children[1].style.display = 'none';
			this.$refs.consultBox.style.right = "-.1rem";
			// this.$refs.consultBox.style.height = (1.84 * document.getElementsByTagName('html')[0].style.fontSize.split('px')[0])+'px';
			this.$refs.consultBox.style.padding = (0.4 * document.getElementsByTagName('html')[0].style.fontSize.split('px')[0])+'px'+' 0';
			this.$refs.consultBox.style.width = (2.08 * document.getElementsByTagName('html')[0].style.fontSize.split('px')[0])+'px';
			this.$refs.consultBox.style.borderTopRightRadius = 0;
			this.$refs.consultBox.style.borderBottomRightRadius = 0;
			this.$refs.consultBox.style.opacity = .4;
      
		},
		endconsult(){
			this.$refs.consultBox.style.opacity = 0.4;
			let timed = setInterval(()=>{
				if(this.$refs.consultBox.style.opacity<=0.8){
					this.$refs.consultBox.style.opacity += 0.1; 
				}else{
					this.$refs.consultBox.style.opacity = 1; 
					clearInterval(timed);
				}
			},100)
			this.$refs.consultBox.style.borderTopRightRadius = "1rem";
			this.$refs.consultBox.style.borderBottomRightRadius = "1rem";
			this.$refs.consultBox.style.right = '.3rem';
			this.$refs.consultBox.style.height = 'auto';
			this.$refs.consultBox.style.padding = '.4rem .44rem';
			this.$refs.consultBox.style.width = 'auto';
			this.$refs.consultBox.children[1].style.display = 'block';
			this.$refs.consultBox.style.opacity = 1; 
            setTimeout(()=>{
                console.log(this.$refs);
                this.$refs.consultBox.classList.add('transitionAnimation');
                
            },300)
		},
        async getDetailsData(){
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let datadd = {
                peCodes:this.peCodes
            }
            let result = await compareDetails(datadd);
            toast.clear();
            if(result.data.errorCode==0){
                for (let i = 0; i < result.data.data.values.length; i++) {
                    result.data.data.values[i].activeNames = [];
                    for (let j = 0; j < result.data.data.values[i].values.length; j++) {
                        result.data.data.values[i].values[j].setdesc = false;
                        // result.data.data.values[i].values[j].desc.desc = "理发师理发煎熬了煞风景啊设计费卡";
                    }
                }
                this.detailData = result.data.data;
            }else{
                this.$toast.fail(result.data.errorMsg)
            }
        }
    }
}
</script>

<style lang="scss"  >
    .duibidetails-main{
        height: 100%;
        background: #fff;
        box-sizing: border-box;
        padding: 0 .6rem;
        display: flex;
        width: 100%;
        flex-direction: column;
        overflow-y: scroll;
        .detail-head{
            height: max-content;
            .title{
                font-size: .8rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: .8rem;
                background: #F8ECDA;
                border-radius: .08rem;
                padding: .04rem;
                width: max-content;
                margin: .6rem 0;
            }
            .text{
                font-size: .48rem;
                font-family: PingFang SC;
                font-weight: 300;
                color: #919398;
                line-height: .6rem;
            }
            .lastext{
                margin-bottom: .6rem;
            }
            .vs-box{
                width: 100%;
                height: 2.2rem;
                padding: .1rem;
                background: #fff;
                box-sizing: border-box;
                .boxs{
                    background: url(/assets/img/vs-icon.817a7018.png) no-repeat;
                    background-size: 100% 100%;
                    height: 2.2rem;
                    align-items: center;
                    display: flex;
                    padding: 0 .9rem;
                    .timeNull{
                        flex: 1;
                    }
                    .timeText{
                        font-size: .72rem;
                        font-family: PingFang SC;
                        font-weight: 500;
                        color: #FFFFFF;
                    }
                }
            }
            .VsFixed{
                position: fixed;
                padding:.1rem .7rem;
                top: 0;
                left: 0;
                background-color: #fff;
            }
        }
        .detail-list{
            .Lsit-item{
                margin-bottom: .2rem;
                overflow: auto;
                .tj-title{
                    margin: .5rem auto;
                    text-align: center;
                }
                .item-child{
                    margin-bottom: .8rem;
                    .child-title{
                        display: flex;
                        align-items: flex-start;
                        .text{
                            flex: 1;
                            font-size: .52rem;
                            font-family: PingFang SC;
                            font-weight: 400;
                            color: #303133;
                            line-height: .8rem;
                        }
                        .icon{
                            // box-sizing: border-box;
                            width: .6rem;
                            height: .3rem;
                            padding: .3rem .25rem;
                            display: block;
                            background: url("~@/assets/img/tj_duibi_top_icon.png") no-repeat center;
                            background-size: .6rem .3rem;
                        }
                        .iconBottom{

                            // transform:rotate(180deg);
                            // -ms-transform:rotate(180deg); 	/* IE 9 */
                            // -moz-transform:rotate(180deg); 	/* Firefox */
                            // -webkit-transform:rotate(180deg); /* Safari 和 Chrome */
                            // -o-transform:rotate(180deg); 	/* Opera */
                            background: url("~@/assets/img/tj_duibi_bottom_icon.png") no-repeat center;
                            background-size: .6rem .3rem;
                            // -webkit-transition-delay: 0.2s;
                            // -moz-transition-delay: 0.2s;
                            // transition-delay: 0.2s;
                        }
                    }
                    .child-box{
                        margin-bottom: .2rem;
                        height: 1.5rem;
                        display: flex;
                        line-height: 1.5rem;
                        font-size: .64rem;
                        font-family: PingFang SC;
                        font-weight: 400;
                        margin-top: .1rem;
                        color: #303133;
                        &>div{
                            flex: 1;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            &:first-child{
                                background: #E2EFFF;
                            }
                            &:last-child{
                                background: #FFF3E2;
                            }
                            i{
                                display: none;
                            }
                            .arrow-top,.arrow-bottom{
                                width: .34rem;
                                height: .5rem;
                                display: block;
                                margin-left: .2rem;
                            }
                            .arrow-top{
                                background: url("~@/assets/img/arrow_top_ion.png") no-repeat;
                                background-size: 100% 100%;
                            }
                            .arrow-bottom{
                                background: url("~@/assets/img/arrow_bottom_ion.png") no-repeat ;
                                background-size: 100% 100%;
                            }
                        }
                        .text-red{
                            color: #FF671C;
                        }
                    }
                    .desc-text{
                        font-size: .52rem;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #919398;
                        line-height: .8rem;
                    }
                }
            }
        }
    }
</style>